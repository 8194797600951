import React from 'react';
import './Introduction.css';
import { Flex, Text, Image } from '@chakra-ui/react';
import Snow from '../../../assets/images/snow.png';
const Introduction = (prop) => {
  let product = prop.product;
  return (
    <Flex direction={'column'} marginTop={'-40px'}>
      <Flex className='intro_card' mx={10}>
        <Image
          className='snowcard'
          zIndex={2}
          width='100%'
          mx={'auto'}
          mt={'-18px'}
          position='absolute'
          objectFit='contain'
          src={Snow}
        ></Image>
        {/* <img src={contentimg} alt='content_img' /> */}
        <Flex className='transbox' mt={5}>
          <Text className='intro_content'>{product.productDesc}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Introduction;
