/** @format */

import React, { useState, useEffect } from 'react';
import ParticleBackground from './ParticleBackground.js';
import Snow from '../../assets/images/snow.png';
import Light from '../../assets/images/lights.gif';
import Decor from '../../assets/images/snow1.png';
import Introduction from '../../components/MobileUI/Introduction/Introduction';
import ProductInfo from '../../components/MobileUI/privateUI/ProductInfo/ProductInfo';
import ProductInfoFromPBC from '../../components/MobileUI/publicProductInfo/ProductInfoFromPBC';
import AboutUs from '../../components/MobileUI/AboutUs/AboutUs';
// Design Redefined
import ProductHeader from '../../components/MobileUI/HeaderAnime/Header';
import { Container, Flex, Image, Text, useToast } from '@chakra-ui/react';
import Card from '../../components/Card/Card';
import SplashScreen from '../../components/MobileUI/SplashScreen/SplashScreen';

import SocialLinks from '../../components/MobileUI/SocialLinks/SocialLinks';
import countryCodes from './countryCodes';
import { useLocation } from 'react-router-dom';
import './MainPage.css';
import AddGeoLocation from '../../components/CommonFunctions/AddGeoLocation';
import ReadSingleProduct from '../../components/CommonFunctions/DBCalls/ReadSingleProduct';
import axios from 'axios';
import Process from '../../components/MobileUI/Process/Process';
import Sustain from '../../components/MobileUI/Sustainabilty/Sustain';
import SimilarProduct from '../../components/MobileUI/SimilarProduct/SimilarProduct.js';
import ReadBlockchainfromPublic from '../../components/CommonFunctions/BlockchainCalls/ReadBlockchianfromPublic';
import BlockchainDetails from '../../components/MobileUI/publicProductInfo/BlockchainDetails/BlockchainDetails ';
import BackgroundMusic from '../../components/MobileUI/BackgroundMusic.js';
import Christmasmusic from '../../assets/audio/christmas.mp3';
//splash loading functionality
const MainPage = () => {
  const toast = useToast();
  const [transactionHash, setTransactionHash] = useState([]);
  const [blockNumber, setBlockNumber] = useState([]);
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState('');
  const query = new URLSearchParams(useLocation().search);
  const id = query.get('productId') || query.get('pId');
  const batchId = query.get('batchId') || query.get('Id');
  const bId = query.get('bId');
  const [locationStatus, setLocationStatus] = useState(false);
  // constlocationStatus=false;
  const [status, setStatus] = useState(false);
  const [city, setCity] = useState('');
  const [location, setLocation] = useState({});
  const [countryCode, setCountryCode] = useState('');
  const [region, setRegion] = useState('');
  const [subRegion, setSubregion] = useState('');
  const [lati, setLatitude] = useState('0');
  const [longi, setLongitude] = useState('0');
  let [cityCountry, setCityCountry] = useState('');
  const [blockData, setBlockData] = useState();
  // constlocation, city, countryCode, region, subRegion;

  useEffect(() => {
    !status && getLocation();
    const productId = id ? id : 0;
    getProduct(productId);
    bId && getBlockChainData();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  const getProduct = async (productId) => {
    const productFromServer = await ReadSingleProduct(productId);
    setProduct(productFromServer.data);
  };

  // Function to fetch blockchain data
  const getBlockChainData = async () => {
    if (bId === '') return;
    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const response = await ReadBlockchainfromPublic(bId);
      if (response?.data) {
        setBlockData(response?.data?.data);
        setBlockNumber(response?.data?.blockNumber);
        setTransactionHash(response?.data?.transactionHash);
      } else {
        console.log('No Data Avilable');
      }
    } catch (error) {
      toast({
        title: 'No data found ! Batch id doest not exists',
        position: 'top',
        status: 'error',
        isClosable: true,
      });
      console.log(error);
    }
  };
  // Find the latitude and longitude
  async function showPosition(position) {
    const lat = position.coords.latitude;
    const lng = position.coords.longitude;
    setLatitude(lat);
    setLongitude(lng);
    setLocation({
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    });
    // location = {
    //   lat: position.coords.latitude,
    //   lng: position.coords.longitude
    // }
    await axios
      .post(
        `https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&featureTypes=&location=${lng}%2C${lat}`
      )
      .then((res) => {
        if (!res.data.error) {
          if (res.data.address) {
            const cntryName = countryCodes.filter((country) => {
              if (country.alpha3 === res.data.address.CountryCode)
                return country;
            });
            let ccName = res.data.address.City + ',' + cntryName[0].name;
            setCity(res.data.address.City);
            setCountryCode(res.data.address.CountryCode);
            setRegion(res.data.address.Region);
            setCityCountry(ccName);
            setSubregion(res.data.address.subregion);
            setLocationStatus(true);
            // locationStatus=true;
          } else {
            console.log('Address is not available');
          }
        }
      });
  }
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      alert('Geolocation is not supported by this browser');
    }
  };

  // Location data object to be posted
  const locationData = {
    productId: product.productId,
    productName: product.productName,
    location: {
      lat: lati,
      lng: longi,
    },
    city: city,
    countryCode: countryCode,
    region: region,
    subRegion: subRegion,
    cityCountry: cityCountry,
  };
  if (locationStatus && product) {
    setLocationStatus(false);
    AddGeoLocation(locationData);
  }
  // console.log(locationStatus,!returnStatus , loading , product )
  // if (locationStatus && loading && product ) {
  //   // setLocationStatus(false)
  //   locationStatus=false;
  //  let
  //   console.log(rStatus)
  //  setReturnStatus(rStatus);
  // }
  // Error generated when fetching Geolocation
  function showError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert('User denied the request for Geolocation.');
        setCity('Permission denied');
        setCountryCode('Permission denied');
        setRegion('Permission denied');
        setSubregion('Permission denied');
        setCityCountry('Permission denied');
        setLocationStatus(true);
        break;
      case error.POSITION_UNAVAILABLE:
        alert('Location information is unavailable.');
        break;
      case error.TIMEOUT:
        alert('The request to get user location timed out.');
        break;
      case error.UNKNOWN_ERROR:
        alert('An unknown error occurred.');
        break;
    }
  }

  const [windoWidth, setWindowWidth] = useState(window.innerWidth);
  const txt = '⚠️Please switch to mobile screen mode';
  const txt2 = '';
  const switchTxt = windoWidth >= 850 ? txt : txt2;

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <Container style={{ maxHeight: '0px' }}>
      {loading ? (
        <SplashScreen />
      ) : (
        <Flex
          width={'100%'}
          overflow={'hidden'}
          flexDirection={'column'}
          position={'relative'}
        >
          <ParticleBackground />
          <Image
            ml={'-70px'}
            width={'180px'}
            objectFit={'contain'}
            position={'absolute'}
            src={Light}
          ></Image>
          <Image
            right={0}
            mr={'-70px'}
            width={'180px'}
            objectFit={'contain'}
            position={'absolute'}
            src={Light}
          ></Image>
          <ProductHeader product={product} />
          <Text
            position={'relative'}
            textAlign={'center'}
            color={'red'}
            fontSize={30}
            fontFamily={'sans-serif'}
            fontWeight={'bold'}
          >
            {switchTxt}
          </Text>
          <Card
            position={'relative'}
            bg={'#7ABAFF'}
            pt={'8px'}
            w={'60%'}
            borderRadius={'29px 29px 0px 0px'}
            justifyContent={'center'}
            fontWeight={'bold'}
            boxShadow='0px 4px 4px rgba(0, 0, 0, 0.7)'
            style={{ fontFamily: 'Poppins, sans-serif' }}
            mx='auto'
            h={'65px'}
            zIndex={'1'}
            className='headText'
          >
            <Image
              className='snowcard'
              width='100%'
              mx={'auto'}
              mt={'-20px'}
              position='absolute'
              objectFit='contain'
              src={Snow}
            ></Image>
            <Text
              fontSize={22}
              mt={'10px'}
              color={'white'}
              textTransform={'capitalize'}
              style={{ textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)' }}
              textAlign={'center'}
              className='HeadTxt animate__animated animate__fadeInUp animate__slow'
            >
              {product.productName}
            </Text>
          </Card>

          {/* <Header /> */}
          <Flex
            zIndex={2}
            bgColor={'#CADCEB'}
            position={'relative'}
            flexDir={'column'}
            className='productCard animate__animated animate__fadeInUp'
          >
            <Image
              zIndex={5}
              width='100%'
              mt={'-65px'}
              // position='absolute'
              objectFit='contain'
              src={Decor}
              mx={'auto'}
            ></Image>
            {product && <Introduction product={product} />}
            <br></br>
            {batchId && <ProductInfo assetId={batchId} />}
            {bId && <ProductInfoFromPBC blockData={blockData && blockData} />}
            {bId && (
              <BlockchainDetails
                transactionHash={transactionHash}
                blockNo={blockNumber}
              />
            )}
            {product && product.processList ? (
              <Process product={product} />
            ) : (
              ' '
            )}
            {/* { (product && product.inputList) ? <Contents product={product} /> : ' '} */}
            <br></br>

            <AboutUs />
            <Sustain />
            <SimilarProduct
              location={{
                city,
                countryCode,
                region,
                subRegion,
                cityCountry,
              }}
            />
            <SocialLinks />
            <br></br>
          </Flex>
        </Flex>
      )}
    </Container>
  );
};

export default MainPage;
