import React from 'react';
import { Text, Flex, Image } from '@chakra-ui/react';
import './AboutUs.css';
import Snow from '../../../assets/images/snow.png';
const AboutUs = () => {
  return (
    <Flex direction={'column'} marginTop={10} marginBottom={50}>
      <Text className='aboutus'>About Us </Text>
      <Flex className='line'></Flex>
      <Flex className='intro_card' marginBottom={5}>
        <Image
          className='snowcard'
          zIndex={2}
          width='100%'
          mx={'auto'}
          mt={'-22px'}
          position='absolute'
          objectFit='contain'
          src={Snow}
        ></Image>
        {/* <img src={contentimg} alt='content_img' /> */}
        <Flex className='transbox'>
          <Text className='intro_content'>
            Roehill Springs Distillery is a small artisan Distillery, based in a
            building on the third generation, family-run farm at Newtack,
            Crossroads, Grange, KEITH.
            <br />
            <br />
            As this is a small artisan business, all work is carried out by
            family members who live on the farm. It is distilled, bottled,
            labelled, waxed and packaged by hand, on-site at our farm which has
            been in the Morrison family for over 60 years.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AboutUs;
