import axios from 'axios';
import { pbcuri, pbcapi_key } from '../../../config';
import Swal from 'sweetalert2';

async function CreateBlockchainDataPublic(batchId, dynamicData, functionality) {
  const header = {
    'content-type':
      'application/json, text/plain, application/x-www-form-urlencoded',
    headers: { Authorization: `Bearer ${pbcapi_key}` },
  };

  const serverdata = await axios
    .post(
      `${pbcuri}/createAsset`,
      { batchId, dynamicData, functionality },
      header
    )
    .then((res) => {
      if (res.status === 200) {
        //if sucessfully added then return true value
        return {
          status: true,
          message: `Blockchain data created successfully, Created new block`,
          data: res?.data,
        };
      }
      return res;
    })
    .catch((error) => {
      if (error.message === 'Request failed with status code 400') {
        return {
          status: false,
          message: `Oops! Batch Id alreadt exist`,
        };
      } else if (error.message === 'Network Error') {
        return {
          status: false,
          message: `Oops! Network not available, please check your network connectivity`,
        };
      } else {
        return {
          status: false,
          message: `Oops! Somthing went wrong`,
        };
      }
    });

  let product = serverdata;
  return product;
}
export default CreateBlockchainDataPublic;
