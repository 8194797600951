/** @format */

import React, { useState, useRef, useEffect } from 'react';
import { Container, Flex, Image, IconButton } from '@chakra-ui/react';
import { TbMusicOff } from 'react-icons/tb';
import { HiOutlineMusicNote } from 'react-icons/hi';
import './Header.css';
import 'animate.css';
import Music from '../../../assets/images/music.mp3';
function Header({ product }) {
  // Music player state
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.04);
  const audioRef = useRef(null);

  // Check if autoplay is allowed
  useEffect(() => {
    const checkAutoplay = async () => {
      try {
        await audioRef.current.play();
        setIsPlaying(true);
      } catch (error) {
        // Autoplay not allowed
        setIsPlaying(false);
      }
    };
    checkAutoplay();
  }, []);

  // Set audio volume on mount and when volume changes
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  // Toggle play/pause music
  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  // Determine the image link
  const link =
    product?.imageLink ||
    'https://res.cloudinary.com/dj6eghdzu/image/upload/v1647840189/GIN%20IMAGES/NavyStrength_idglgj.png';

  return (
    <Flex
      alignItems='center'
      mt='100px'
      alignSelf='center'
      justifyContent='center'
    >
      {/* Music Control Button */}

      <IconButton
        position='absolute'
        top='20px'
        left='20px'
        icon={
          isPlaying ? (
            <HiOutlineMusicNote size={15} color='white' />
          ) : (
            <TbMusicOff size={15} color='white' />
          )
        }
        onClick={togglePlay}
        aria-label='Toggle Music'
        borderRadius='50%'
        padding={'10px'}
        backgroundColor={'#A60D34'}
        border={'2px solid white'}
        boxShadow={'lg'}
      />

      {/* Audio element */}
      <audio ref={audioRef} src={Music} loop />

      <Flex className='outerLayout' ms='-10'>
        <Flex
          className='water animate__animated animate__fadeInDown animate__slower'
          justifyContent='center'
        >
          <div style={{ width: '70%', height: '100%' }}>
            <Image
              src={link}
              display='none'
              className='image animate__animated animate__fadeInUp animate__delay-2s'
              w='100%'
              h='100%'
              zIndex={5}
            />
          </div>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Header;
