import axios from 'axios';
import React, { useState } from 'react';
import {
  FormLabel,
  Input,
  Td,
  Flex,
  Th,
  Tr,
  Table,
  Text,
  TableCaption,
  Button,
  FormControl,
  Tbody,
} from '@chakra-ui/react';
import ReadBlockchainData from '../../components/CommonFunctions/BlockchainCalls/ReadBlockchianfromPublic';
const TrackList = (params) => {
  let [blockchainData, SetBlockchainData] = useState([]);
  const [Status, setStatus] = useState(false);
  const [batchId, setBatchId] = useState('');
  const [updateStatus, setUpdateStatus] = useState(false);
  // const [error,setError] = useState('')
  var prod;
  const logData = localStorage.getItem('tg-token');
  let logDetails = JSON.parse(logData);

  async function viewProduct(e) {
    e.preventDefault();
    setStatus(true);
    if (!batchId) {
      alert('Please Enter Batch Id');
      return;
    }
    // try{
    let prodata = await ReadBlockchainData(batchId);
    if (prodata?.data) {
      setUpdateStatus(true);
      setStatus(false);
      SetBlockchainData(prodata.data?.data);
    } else {
      setStatus(false);
      setUpdateStatus(false);
      SetBlockchainData(null);
    }
  }
  params.setBatchId(batchId);
  params.setUpdateStatus(updateStatus);
  return (
    <Flex
      direction={{ base: 'column', md: 'column' }}
      w='100%'
      justifyContent={'space-between'}
      background='transparent'
      marginBottom={'4'}
    >
      <form onSubmit={viewProduct}>
        <Flex
          direction={{ base: 'column', md: 'column' }}
          w='100%'
          justifyContent={'space-between'}
          background='transparent'
          marginBottom={'4'}
        >
          <FormControl
            variant='floating'
            id='parameterName'
            mr={3}
            mb={{ base: '6px', md: '0', xl: '0' }}
            isRequired
          >
            <Input
              type='text'
              focusBorderColor={'transparent'}
              bg={'blackAlpha.100'}
              color={'white'}
              id='batchId'
              placeholder='Batch Id'
              value={batchId}
              onChange={(e) => setBatchId(e.target.value)}
              required
            />
            <FormLabel htmlFor='username'>Batch Id</FormLabel>
          </FormControl>
          {!Status ? (
            <Button
              type='submit'
              class='bn632-hover bn26'
              style={{ marginTop: '25px' }}
              w={{ base: '100%', md: '25%' }}
            >
              {' '}
              View{' '}
            </Button>
          ) : (
            <Button
              isLoading
              loadingText='Loading'
              // variant='outline'
              spinnerPlacement='start'
              style={{ marginTop: '25px' }}
              w={{ base: '100%', md: '35%' }}
            >
              Submit
            </Button>
          )}
        </Flex>
      </form>
      {blockchainData && blockchainData.length > 0 && (
        <Flex
          direction={{ base: 'column', md: 'row' }}
          w='100%'
          justifyContent={'space-between'}
          background='transparent'
          marginBottom={'4'}
        >
          <Table>
            <Tbody justifyItems={'center'} p={'5px'}>
              {blockchainData.length > 0 ? (
                blockchainData.map((data, i) => (
                  <React.Fragment key={i}>
                    {Array.isArray(data)
                      ? data.map((produc, j) => (
                          <React.Fragment key={j}>
                            <Tr key={i}>
                              <Th color='white'> {produc[0]}</Th>
                              <Td color='white'>
                                {' '}
                                {typeof produc[1] === 'string' &&
                                isNaN(produc[1])
                                  ? produc[1]
                                  : parseFloat(produc[1]).toFixed(2)}
                              </Td>
                            </Tr>
                          </React.Fragment>
                        ))
                      : null}
                  </React.Fragment>
                ))
              ) : (
                <>
                  <Text color='white'>
                    No Data Available. Please enter a valid batch Id
                  </Text>
                </>
              )}
            </Tbody>
          </Table>
        </Flex>
      )}
      {/* // : (
      //   <Text color='white'>
      //     No Data Available. Please enter a valid batch Id
      //   </Text>
      // )} */}
    </Flex>
  );
};

export default TrackList;
