import { Box, keyframes } from '@chakra-ui/react';
import { useMemo } from 'react';

const ParticleBackground = () => {
  const totalParticles = 200;

  // Generate particles with random properties
  const particles = useMemo(() => {
    const particlesArray = [];
    for (let i = 0; i < totalParticles; i++) {
      const randomX = Math.random() * 100; // Horizontal position
      const randomOffset = (Math.random() - 0.5) * 20; // Offset for horizontal movement
      const randomScale = Math.random() * 0.8 + 0.2; // Scale of the snow particle
      const fallDuration = Math.random() * 20 + 10; // Duration for falling animation
      const fallDelay = Math.random() * -30; // Delay for staggered start

      // Define the falling animation keyframes
      const fallAnimation = keyframes`
        from {
          transform: translate(${randomX}vw, -10px) scale(${randomScale});
        }
        to {
          transform: translate(${
            randomX + randomOffset
          }vw, 100vh) scale(${randomScale});
        }
      `;

      // Push each particle with random styling and animation
      particlesArray.push(
        <Box
          key={i}
          position='absolute'
          width='5px'
          height='5px'
          background='white'
          borderRadius='50%'
          opacity={Math.random()}
          animation={`${fallAnimation} ${fallDuration}s ${fallDelay}s linear infinite`}
        />
      );
    }
    return particlesArray;
  }, [totalParticles]);

  return (
    <Box
      height='100%'
      width={'100%'}
      overflow='hidden'
      background='radial-gradient(ellipse at bottom, #263349 0%, #0D1B33 100%)'
      position='absolute'
      filter='drop-shadow(0 0 10px white)'
    >
      {particles}
    </Box>
  );
};

export default ParticleBackground;
