const dev = process.env.NODE_ENV !== 'production';

export const server = dev
  ? 'https://tg-roehillsprings.tk'
  : 'https://tg-roehillsprings.tk';
// export const server = 'http://localhost:5000';
export const uri = dev
  ? 'https://tg-roehillsprings.tk'
  : 'https://tg-roehillsprings.tk';
// export const uri = 'http://localhost:3000';
export const bcuri = dev
  ? 'https://server.trackgin.com/api/v1/asset'
  : 'https://server.trackgin.com/api/v1/asset';

export const pbcuri = dev
  ? 'https://pbcserver.tgwebapps.com/api/v1'
  : 'https://pbcserver.tgwebapps.com/api/v1';
export const tag_name = 'RoeHillS';
export const api_key =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzZTQ4MGJkNjFmNGRmNWJiNTQ4YmQxNyIsImlhdCI6MTY3NTkxOTU0OX0.UZTKbmM0U-cP8hwE5-ieA0OIvp75oHIEf31swt01Ayo';
export const pbcapi_key =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1MmZhOTY5NmE0NjYyNDU4ODc4NzU1NCIsImlhdCI6MTY5NzYyMjM3N30.urKrnCUe7o5_SshY9sbAr6Og7Tly97HA3LT15ckaqB8';
export const networkExplorer = !dev
  ? 'https://polygonscan.com/tx/'
  : 'https://polygonscan.com/tx/';
